import { types } from '../actionTypes/action-types';

export const userActions = {
	userLogin: (user) => {
		return {
			type: types.USER_LOGIN,
			payload: user
		};
	},
};
