import {
	gql
} from '@apollo/client';

export const GET_ENTRIES = gql`
  	query Query {
		entries {
			entryData,
			title,
			date
		}
  	}
`;

export const SUBMIT_POST = gql`
	mutation Mutation($entry: EntryInput!) {
		saveEntry(entry: $entry) {
			title
			entryData
			date
		}
	}
`;