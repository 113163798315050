import { types } from '../actionTypes/action-types';

const initialState = {
	user: null,
};

const userReducer = (state = initialState, action = {}) => {
	switch(action.type) {
		case types.USER_LOGIN: 
			return {
				...state,
				user: action.payload
			};
		default:
			return state;

	}
};

export default userReducer;