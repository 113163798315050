

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import RequireAuth from '../components/requireAuth/requireAuth';
import { userActions } from '../store/actions/userActions';

const mapStateToProps = (state) => {
	return {
		userState: state.user
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		userLogin: userActions.userLogin,
	}, dispatch);
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(RequireAuth);