import { types } from '../actionTypes/action-types';

const initialState = {
	orientation: '',
	isMobile: false,
	isPortrait: false,
};

export const screenReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case types.LANDSCAPE: {
			return { ...state, orientation: action.payload, isPortrait: false };
		}
		case types.PORTRAIT: {
			return { ...state, orientation: action.payload, isPortrait: true };
		}
		case types.DESKTOP: {
			return { ...state, orientation: action.payload };
		}
		case types.TO_LANDSCAPE: {
			return { ...state, orientation: action.payload };
		}
		case types.TO_PORTRAIT: {
			return { ...state, orientation: action.payload };
		}
		case types.MOBILE: {
			return { ...state, isMobile: action.payload };
		}
		default: return state;
	}
};