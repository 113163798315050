import { types } from '../actionTypes/action-types';
import { screenConst } from '../../constants/screenConstatns';

export const screenActions = {
	rotationToLandscape: () => {
		return { 
			type: types.TO_LANDSCAPE,
			payload: screenConst.MOBILE_LANDSCAPE
		};
	},
	rotationToPortrait: () => {
		return { 
			type: types.TO_PORTRAIT,
			payload: screenConst.MOBILE_PORTRAIT
		};
	},
	rotationLandscape: () => {
		return { 
			type: types.LANDSCAPE,
			payload: screenConst.MOBILE_LANDSCAPE
		};
	},
	rotationPortrait: () => {
		return { 
			type: types.PORTRAIT,
			payload: screenConst.MOBILE_PORTRAIT
		};
	},
	isMobile: (isIt) => {
		return {
			type: types.MOBILE,
			payload: isIt
		};
	},
	dektopScree: () => {
		return {
			type: types.DESKTOP,
			payload: ''
		};
	}
};