import React from 'react';
import {
	useQuery,
	ApolloProvider
} from '@apollo/client';
import Timeline from '@material-ui/lab/Timeline';
import RateReviewIcon from '@material-ui/icons/RateReview';
import TimeLineItem from '../timeline/customTimeLineItem/customTimeLineItem';
import client from '../../shared/apollo';
import { GET_ENTRIES } from '../../shared/qglQueries';
import './entries.css';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function EntriesData() {
	const { loading, error, data } = useQuery(GET_ENTRIES);

	if (loading) return 'Loading...';
	if (error) return `Error! ${error.message}`;

	return (
		<Timeline align="alternate">
			{data.entries.map((entry, i) => (
				<TimeLineItem key={i}
					text={entry.entryData}
					title={entry.title}
					date={new Date(entry.date).toDateString()}
					IconProp={RateReviewIcon}>
				</TimeLineItem>
			))}
			<FontAwesomeIcon
				size="2x" 
				className="begining-icon" 
				icon={faArrowAltCircleUp} />
		</Timeline>
	);
}


export const Entries = () => {
	return (
		<>
			<ApolloProvider client={client}>
				<EntriesData />
			</ApolloProvider>
		</>
	);
};

export default Entries;
