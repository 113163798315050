import { combineReducers } from 'redux';
import { cardsReducer } from './cardsReducer';
import { screenReducer } from './screenReducer';
import { spinnerReducer } from './spinnerReducer';
import userReducer from './uderReducer';

const rootReducer = combineReducers({
	screen: screenReducer,
	spinner: spinnerReducer,
	cards: cardsReducer,
	user: userReducer,
});

export default rootReducer;