import React, { lazy, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import './App.css';
import history from './shared/history';
import { Spinner } from './components/common/spinner/spinner';
import Entries from './components/entries/entries';
import LogEntries from './components/logEntry/logEntry';
import RequireAuthContainer from './containers/requireAuthContainer';
import LogUserInContainer from './containers/logUserInContainer';
library.add(fab);
library.add(fas);
library.add(far);
const Home = lazy(() => import('./components/home/home'));
const AboutMe = lazy(() => import('./components/me/me'));
const TimelineBlog = lazy(() => import('./components/timeline/timeline'));
const WorkExperience = lazy(() => import('./components/WorkExperience/WorkExperience'));
const NotFound = lazy(() => import('./components/notFound/notFound'));

function App() {

	const screenState = useSelector((state) => state.screen);

	const CLASS_NAME = 'App';

	const mobile_class_name = CLASS_NAME + ' ' + screenState.orientation;

	return (
		<div className={screenState.isMobile ? mobile_class_name : CLASS_NAME} >
			<Router history={history}>
				<Suspense fallback={<Spinner />}>
					<Switch>
						<Route 
							exact 
							path="/"
							component={Home} />

						<Route 
							path="/me"
							component={AboutMe} />

						<Route 
							path="/timeline"
							component={TimelineBlog} />

						<Route 
							path="/work-experience-timeline"
							component={WorkExperience} />

						<Route
							path="/my-blog"
							render={() => {
								return <NotFound 
									message={'NOT SIGNAL... YET!'} />;
							}} />
						
						<Route
							path="/login"
							component={LogUserInContainer} />

						<Route
							path="/log-entry"
							render={() => (
								<RequireAuthContainer>
									<LogEntries/>
								</RequireAuthContainer>
							)} />

						<Route
							path="/entries"
							render={Entries} />

						<Route
							path="*"
							component={NotFound} />
					</Switch>
				</Suspense>
			</Router>
		</div>
	);
}

export default App;
