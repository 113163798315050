
import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { userConstants } from '../../../containers/userConstants';
import { auth, GoogleAuthProvider } from '../../../firebase';
import history from '../../../shared/history';

export const LogUser = ({ userLogin }) => {


	useEffect(() => {
		const provider = new GoogleAuthProvider();
		auth().signInWithPopup(provider)
			.then((success) => {

				let user = success.user;

				const userObj = {
					username: user.displayName,
					email: user.email,
					uid: user.uid,
					img: user.photoURL
				};

				console.log(userObj);

				localStorage.setItem(userConstants.userKey, JSON.stringify(userObj));

				userLogin(userObj);

				history.push('/log-entry');

			}).catch(err => err.message);
	}, [userLogin]);

	return (<span>Loaging...</span>);
};

LogUser.propTypes = {
	userLogin: Proptypes.func
};
