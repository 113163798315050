import { types } from '../actionTypes/action-types';

const initialState = {
	loadFinished: false,
	isContentLoaded: false,
};

export const spinnerReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case types.FINISHED_LOAD: {
			return { ...state, loadFinished: action.payload };
		}
		case types.CONTENT_LOADED: {
			return { ...state, isContentLoaded: action.payload };
		}
		default: return state;
	}
};