import { types } from '../actionTypes/action-types';

export const spinnerActions = {
	loadFinished: (finished = false) => {
		return { 
			type: types.FINISHED_LOAD,
			payload: finished
		};
	},
	contentLoaded: (loaded) => {
		return { 
			type: types.CONTENT_LOADED,
			payload: loaded
		};
	}
};
