import React from 'react';
import PropTypes from 'prop-types';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { sanitize } from 'dompurify';
import './customTimeLineItem.css';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: '6px 16px',
	},
	secondaryTail: {
		backgroundColor: theme.palette.secondary.main,
	},
}));


const TimeLineItem = ({text, date, title, IconProp}) => {

	const classes = useStyles();

	return (
		<>
			<TimelineItem>
				<TimelineOppositeContent>
					<Typography variant="body2" color="textSecondary">
						{date}
					</Typography>
				</TimelineOppositeContent>
				<TimelineSeparator>
					<TimelineDot color="primary">
						<IconProp />
					</TimelineDot>
					<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent>
					<Paper elevation={8} className={classes.paper}>
						<Typography variant="h6" component="h1">
							{title}
						</Typography>
						<Typography component={'span'}>
							Because you need strength
							<div 
								dangerouslySetInnerHTML={{ __html: sanitize(text) }}
								className="entry-desc">
							</div>

						</Typography>
					</Paper>
				</TimelineContent>
			</TimelineItem>
		</>
	);
};

TimeLineItem.propTypes = {
	text: PropTypes.string,
	date: PropTypes.string,
	title: PropTypes.string,
	IconProp: PropTypes.any.isRequired,
};

export default TimeLineItem;