
export const types = {
	PORTRAIT: 'PORTRAIT',
	LANDSCAPE: 'LANDSCAPE',
	TO_PORTRAIT: 'TO_PORTRAIT',
	TO_LANDSCAPE: 'TO_LANDSCAPE',
	MOBILE: 'MOBILE',
	DESKTOP: 'DESKTOP',
	FINISHED_LOAD: 'FINISHED_LOAD',
	CONTENT_LOADED: 'CONTENT_LOADED',
	SET_IMGS_URLS: 'SET_IMGS_URLS',
	SET_CARD_POSITIONS: 'SET_CARD_POSITIONS',
	USER_LOGIN: 'USER_LOGIN',
};