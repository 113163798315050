import { types } from '../actionTypes/action-types';

const initialState = {
	imgURLs: [],
	positions: []
};

export const cardsReducer = (state = initialState, action = {}) => {
	switch (action.type) {
		case types.SET_IMGS_URLS: {
			return {...state, imgURLs: action.payload};
		}
		case types.SET_CARD_POSITIONS: {
			return {...state, positions: action.payload};
		}
		default: return state;
	}
};