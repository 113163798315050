import React from 'react';
import { Redirect } from 'react-router-dom';
import { userConstants } from '../../containers/userConstants';

export default function RequireAuth(props) {
	const { children, userState } = props;

	if (!userState.user && !localStorage.getItem(userConstants.userKey)) {
		return <Redirect to="/login" />;
	}

	return children;
}