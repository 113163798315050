import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { spinnerActions } from '../../../store/actions/spinnerActions';
import './spinner.css';
import SpinnerContent from './spinnerContent/SpinnerContent';

export function Spinner({loadingDone, spinnerActions}) {

	const [spinnerOff, setSpinnerOff] = useState(false);

	const onAnimationEnd = () => {
		setSpinnerOff(true);
		spinnerActions.contentLoaded(true);
	};

	const className = 'spinner-container';

	return ReactDOM.createPortal(
		<SpinnerContent 
			spinnerOff={spinnerOff}
			onAnimationEnd={onAnimationEnd} 
			className={className}
			loadingDone={loadingDone} />, 
		document.querySelector('body')
	);
}

function mapStateToProps(state) {
	return {
		spinnerState: state.spinner,
		cardsState: state.cards,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		spinnerActions: bindActionCreators(spinnerActions, dispatch),
	};
}

Spinner.propTypes = () => { 
	return { 
		children: PropTypes.any,
	}; 
};


export default connect(mapStateToProps, mapDispatchToProps)(Spinner);