import React, {
	useState
} from 'react';
import {
	Box,
	Button,
	FormControl,
	Input,
	InputLabel,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import RichTextEditor from 'react-rte';
import { sanitize } from 'dompurify';
import '@material-ui/core/styles';
import client from '../../shared/apollo';
import './logEntry.css';
import { GET_ENTRIES, SUBMIT_POST } from '../../shared/qglQueries';
import history from '../../shared/history';

const LogEntries = () => {

	const value = RichTextEditor.createEmptyValue();
	const [titleValue, setTitleValue] = useState('');
	const [htmlAsText, setHTMLAsText] = useState('');
	const [editorState, setEditorState] = useState({value});
	const [dateVal, setDateVal] = useState(new Date());


	const onEditorStateChanges = (rte) => {
		setEditorState({value: rte});
		setHTMLAsText(rte.toString('html').replaceAll(/javascript:/ig, ''));
	};

	const onChangeDate = (newValue) => setDateVal(newValue);

	const onTitleChanges = (titleElm) => setTitleValue(titleElm.target.value);

	const onSubmit = async () => {
		const entry = {
			title: titleValue,
			entryData: htmlAsText,
			date: dateVal };

		await client.mutate({
			variables: { entry },
			mutation: SUBMIT_POST,
			refetchQueries: () => [{ query: GET_ENTRIES }],
		});

		history.push('/entries');
	};

	return (
		<>
			<div>Log Entry</div>
			<Box component="form" noValidate autoComplete="off">
				<FormControl className="entry-form" sx={{ width: '5rem', height: '5rem' }}>
					<InputLabel
						className="entry-fields"
						htmlFor="entry-text">Title</InputLabel>
					<Input
						sx={{ marginBottom: '5rem' }}
						className="entry-fields entry-text"
						placeholder='Entry Title'
						value={titleValue}
						onChange={onTitleChanges}
						aria-describedby="entry-text" />
					<RichTextEditor
						className='log-entry-rich-edit'
						value={editorState.value}
						onChange={onEditorStateChanges}
					/>
					<KeyboardDatePicker
						className="entry-fields"
						label="Date of entry"
						value={dateVal}
						onChange={onChangeDate}
						animateYearScrolling
						clearLabel
						format="MM/DD/yyyy"
					/>
					<Box spacing={2} sx={{ padding: '1rem' }} direction="row">
						<Button
							color="primary"
							onClick={onSubmit}
							variant="contained">
							Submit
						</Button>
					</Box>
				</FormControl>
			</Box>

			<div className='dgr-html-element' dangerouslySetInnerHTML={{__html: sanitize(htmlAsText)}}>
			</div>
		</>
	);
};


export default LogEntries;
