import { createStore,applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';


export default function configureStore() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? createStore(
		rootReducer,
		compose(applyMiddleware(thunk))
	) : createStore(
		rootReducer,
		compose(
			applyMiddleware(thunk),
			window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : x => x,
		),
	);
}