import React from 'react';
import PropTypes from 'prop-types';

const SpinnerContent = ({
	spinnerOff,
	onAnimationEnd,
	loadingDone,
	className
}) => {
	return !spinnerOff ? <div onAnimationEnd={onAnimationEnd} className={+ loadingDone ? `${className} fade-spinner` : className}>
		<svg
			width="99"
			className="spinner-svg"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 15.2 19.28">
			<g width="30" height="40" id="Layer_2" data-name="Layer 2">
				<g id="Set_of_64_UI_Icons" data-name="Set of 64 UI Icons">
					<g id="Elliptical_Arrows_2" data-name="Elliptical Arrows 2">
						<path d="M8.2,2.14a.65.65,0,0,1-.6-.6V.34c0-.3-.3-.4-.6-.3L3.2,2.94a.67.67,0,0,0,0,1L7,6.84c.2.2.6,0,.6-.3V5.44a.64.64,0,0,1,.8-.6,5.1,5.1,0,0,1,3.5,7.8,1.25,1.25,0,0,0,.3,1.7h0a1.17,1.17,0,0,0,1.7-.3,7.46,7.46,0,0,0,1.3-4.2A7.65,7.65,0,0,0,8.2,2.14Z" />
						<path d="M7,17.14a.65.65,0,0,1,.6.6v1.2c0,.3.3.4.6.3l3.8-2.9a.67.67,0,0,0,0-1l-3.8-2.9c-.2-.2-.6,0-.6.3v1.1a.64.64,0,0,1-.8.6,5.1,5.1,0,0,1-3.5-7.8A1.25,1.25,0,0,0,3,4.94H3a1.17,1.17,0,0,0-1.7.3A7.46,7.46,0,0,0,0,9.44,7.73,7.73,0,0,0,7,17.14Z" />
					</g>
				</g>
			</g>
		</svg>
	</div> : null;
};

SpinnerContent.propTypes = () => { 
	return { 
		children: PropTypes.any,
	}; 
};

export default SpinnerContent;