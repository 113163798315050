import {
	ApolloClient,
	InMemoryCache,
} from '@apollo/client';
import env_vars from '../config';

// export default new ApolloClient({
//   uri: "http://localhost:4000/graphql",
// });


export default new ApolloClient({
	cache: new InMemoryCache(),
	uri: env_vars.api_url
});