import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyC5u5Gw5ARYrh1VsL914aO8tvE_hLifrHA',
	authDomain: 'portfolio-f0a03.firebaseapp.com',
	databaseURL: 'https://portfolio-f0a03.firebaseio.com',
	projectId: 'portfolio-f0a03',
	storageBucket: 'portfolio-f0a03.appspot.com',
	messagingSenderId: '192793742334',
	appId: '1:192793742334:web:cb7932dbd6752f339c4eaf',
	measurementId: 'G-LXW3BMJKRB'
};

// Initialize Firebase

firebase.initializeApp(firebaseConfig);
// firebase.analytics(firebase.app);

const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;

const auth = firebase.auth;

const storage = firebase.storage();

export { auth, GoogleAuthProvider, firebase, storage as default };